@import url(https://use.typekit.net/fdh1rtf.css);
body {
  margin: 0;

  font-family: neue-haas-grotesk-text, sans-serif;
  font-weight: 400; /* 400 || 700 */
  font-style: normal;
  font-size: 14px;

  line-height: 2em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  scroll-behavior: smooth;
}

/* #root {
  width: 100%;
  position: fixed;
} */

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

a {
  text-decoration: none;
  color: inherit;
}

